import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="w-full bg-base-100 bottom-0">
        
          <div className="flex justify-center py-8">
            <div className="">
              
              
              <p>All rights reserved. www.janpluhar.cz</p>
            </div>
          </div>
       
      </footer>
    </>
  );
};

export default Footer;
