import { useState } from "react";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import SocialsMenu from "./socials/SocialsMenu";
import Logo from "./images/Logo";
import Socials from "./socials/SocialsSidebar";
import LogoBlack from "./images/LogoBlack";
import { SlClose } from "react-icons/sl";

const Nav = () => {
  const [menuOpen, setNav] = useState(false);

  const handleNav = () => {
    setNav(!menuOpen);
  };

  return (
    <nav className="fixed w-full h-20 z-50 bg-base-100">
      <div className="flex justify-between items-center w-full h-full px-6 xl:px-16">
        <Logo />
        <div>
          <ul className="hidden md:flex">
            <Link to="/">
              <li className="text-white text-gray ml-10 hover:text-green-400 text-lg ease-in duration-200">
                Resume
              </li>
            </Link>
            <Link to="/technologies">
              <li className="text-white ml-10 hover:text-green-400 text-lg ease-in duration-200">
                Technologies
              </li>
            </Link>
            <Link to="/appsportfolio">
              <li className="text-white ml-10 hover:text-green-400 text-lg ease-in duration-200">
                Apps
              </li>
            </Link>
            <Link to="/contact">
              <li className="text-white ml-10 hover:text-green-400 text-lg ease-in duration-200">
                Contact
              </li>
            </Link>
            <SocialsMenu />
          </ul>
        </div>
        <div onClick={handleNav} className="md:hidden cursor-pointer pl-24">
          <AiOutlineMenu
            size={25}
            className="fill-white hover:fill-green-400 ease-in duration-200"
          />
        </div>
        <div
          className={
            menuOpen
              ? "fixed left-0 top-0 w-[50%] h-screen bg-green-400 shadow-md p-10 ease-in-out duration-500"
              : "fixed left-[-100%] top-0 left-0 h-screen w-[65%]  p-10 ease-in duration-500 "
          }
        >
          <div className="flex w-full items-center justify-end ml-14">
            <div onClick={handleNav} className="cursor-pointer">
              <SlClose
                size={38}
                className="bg-green-400 rounded-full px-1 py-1 fill-white hover:bg-white hover:fill-black ease-in duration-200"
              />
            </div>
          </div>
          <div className="flex-col py-4">
            <ul>
              <Link to="/">
                <li
                  onClick={() => setNav(false)}
                  className="text-black py-4 cursor-pointer hover:text-white text-lg ease-in duration-200"
                >
                  Resume
                </li>
              </Link>
              <Link to="/technologies">
                <li
                  onClick={() => setNav(false)}
                  className="text-black py-4 cursor-pointer hover:text-white text-lg ease-in duration-200"
                >
                  Technologies
                </li>
              </Link>
              <Link to="/appsportfolio">
                <li
                  onClick={() => setNav(false)}
                  className="text-black py-4 cursor-pointer hover:text-white text-lg ease-in duration-200"
                >
                  Apps
                </li>
              </Link>
              <Link to="/contact">
                <li
                  onClick={() => setNav(false)}
                  className="text-black py-4 cursor-pointer hover:text-white text-lg ease-in duration-200"
                >
                  Contact
                </li>
              </Link>
            </ul>
          </div>

          <Socials />

          <div className="pt-10"></div>
          <LogoBlack />
          <div className="flex flex-row justify-center items-center pt-3">
            <p className="text-black font-medium text-xs">www.janpluhar.net</p>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
