import {
  AiOutlineInstagram,
  AiOutlineGithub,
  AiOutlineFacebook,
  AiOutlineLinkedin,
} from "react-icons/ai";

export default function Socials() {
  return (
    <>
      <div className="flex flex-row justify-around pt-10 items-center">
        <a href="https://www.aedin.com/in/honzapluhar/">
          <AiOutlineLinkedin
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a href="https://github.com/HonzaPluhar?tab=repositories">
          <AiOutlineGithub
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a href="https://www.instagram.com/honza_pluhar/">
          <AiOutlineInstagram
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
        <a href="https://www.facebook.com/pluharhonza">
          <AiOutlineFacebook
            size={25}
            className="fill-black ease-out duration-300 cursor-pointer hover:fill-white"
          />
        </a>
      </div>
    </>
  );
}
