import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import DataForm from "./DataForm";

export default function GetAndDeleteDataTest() {
  // get data from the server and display it
  const [data, setData] = useState([]);

  const serverUrl =
    "https://reactapp-janpluharweb-deploytest-backend.onrender.com/"; // || "http://localhost:4000";

  useEffect(() => {
    fetchData().then(setData);
  }, []);

  // fetch data
  async function fetchData() {
    const response = await fetch(serverUrl);
    const data = await response.json();
    return data;
  }

  //delete data from the server
  async function deleteData(id) {
    const response = await fetch(`${serverUrl}${id}`, {
      method: "delete",
    });
    const data = await response.json();

    if (data.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: data.message,
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "User deleted!",
      });
    }

    return data;
  }

  // Return
  return (
    <div className="container mx-auto">
      <DataForm onUserAdded={() => fetchData().then(setData)} />
      <div className="mt-4 py-12 pb-16 bg-base-300 rounded-2xl shadow-md ">
        <div className="pl-4 font-bold text-green-400">
          {data
            ? data.length > 0
              ? `There are ${data.length} users in the database`
              : "Loading data from mongodb..."
            : "There is no data in mongodb"}
        </div>

        {data.map((i) => (
          <div key={i._id}>
            <div className="w-full my-8 py-4 bg-base-200 shadow-md">
              <div className="w-full px-4">
                <div className="columns-1 px-4 md:columns-2">
                  <div>
                    <p>
                      Name:<span className="pl-4 font-semibold">{i.name}</span>
                    </p>
                  </div>
                  <div>
                    <p>
                      Email:{" "}
                      <span className="pl-4 font-semibold">{i.email}</span>
                    </p>
                  </div>
                  <div>
                    <p className="flex flex-col pt-6">
                      Hashed Password:
                      <span className="font-semibold">{i.password}</span>
                    </p>
                  </div>
                </div>

                <div className="flex justify-end pt-4">
                  <button
                    className="btn btn-md btn-outline btn-error shadow-md"
                    onClick={() => {
                      deleteData(i._id).then(() => {
                        fetchData().then(setData);
                      });
                    }}
                  >
                    Delete record
                  </button>
                </div>
                <div className="pt-4 font-thin text-xs flex justify-normal">
                  <p>
                    ID:
                    {i._id}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
