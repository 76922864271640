import React from "react";
import { motion } from "framer-motion";

const packageJson = require("../../package.json");

const Contact = () => {
  const version = packageJson.version;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="flex justify-center font-semibold text-xl">
        Contact page in progress...
      </div>
      <div className="flex justify-center px-4 py-4">
        <p>
          For now you can contact me at{" "}
          <span className="text-green-400 flex justify-center px-4 py-4">
            hello@janpluhar.net
          </span>
        </p>
      </div>

      <div className="px-4 py-4">
        <div className="flex w-full flex-col border-opacity-50">
          <div className="divider">CHANGELOG</div>
        </div>
        <h3>04.08.2024</h3>
        <div className="mb-8">
          <p className="text-sm text-slate-400">App Version: {version}</p>
        </div>

        <ul>
          <li>- .gitignore and dependecies reworked</li>
          <li>- .env.dist added</li>
          <li>- .DS_store removed</li>
          <li>- Reloading pages with 404 reworked</li>
          <li>- webhook deleted</li>
          <li>- reloading after fetch deleted</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default Contact;
