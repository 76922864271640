import React from "react";
import { motion } from "framer-motion";
import DataForm from "../../components/datafromdb/DataForm";
import GetAndDeleteDataTest from "../../components/datafromdb/GetAndDeleteDataTest";
import { BsArrowLeft } from "react-icons/bs";

const SignUpApp = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="mb-12 px-4">
        <button
          className="btn btn-outline"
          onClick={() => window.history.back()}
        >
          <BsArrowLeft
            size={20}
            className="fill-green-400 ease-out duration-300 cursor-pointer"
          />
          Back
        </button>
      </div>
      <div className="px-4 font-thin">
        <h1 className="font-normal text-xl">Signup form</h1>

        <p>
          This is a simple app to represent CRUD operations with mongodb and
          server side api connection and hashed password + webhook connection
          with email response.
        </p>
      </div>

      <GetAndDeleteDataTest />
    </motion.div>
  );
};

export default SignUpApp;
