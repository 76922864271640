import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Appsportfolio = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="px-4 pb-8 font-thin">
        <p>
          There's a list of applications what I've built to show you my skills.
          You can check them out.
        </p>
      </div>

      <section className="mt-12 px-4 py-4 border-green-300 border-solid border-x-2 rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-200">
        <div className="columns-1 w-full h-full md:columns-2">
          <div>
            <h1 className="font-normal text-2xl mb-2">Signup form</h1>
            <p className="badge badge-info mr-2 text-white">Form</p>
            <p className="badge badge-success mr-2 text-white">MongoDb</p>
            <p className="badge badge-warning mr-2 text-white">Hashed</p>
            <p className="py-2">
              This is a simple app to represent CRUD operations with mongodb and
              server side api connection and hashed password + webhook
              connection with email response.
            </p>
          </div>

          <div className="flex justify-end items-end md:break-before-column md:min-h-32">
            <Link to="/signupapp">
              <button className="btn btn-accent btn-outline btn-block md:btn-wide">
                View app
              </button>
            </Link>
          </div>
        </div>
      </section>
    </motion.div>
  );
};

export default Appsportfolio;
