import React from "react";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className="px-4 py-4">
        <div className="mb-4">
          <h1 className="font-bold text-2xl">Jan Pluhar</h1>
          <h2 className="text-green-400 italic">Full-Stack Developer</h2>
        </div>
        <div className="mb-8">
          <p>
            <span className="font-semibold">Age: </span> 30
          </p>
          <p>
            <span className="font-semibold">Location: </span> Liberec, Czech
            Republic
          </p>
        </div>
        <div className="mb-8">
          <h3 className="mb-1 text-xl text-green-400">Profile</h3>

          <p>
            I am a full-stack developer with a passion for creating web
            applications. I have experience with JavaScript, React, Node.js,
            Express, MongoDB, and more. I am always looking to learn new
            technologies and improve my skills. I am currently seeking
            opportunities to work with a team of developers to create amazing
            applications.
          </p>
        </div>

        <div className="mb-8">
          <h3 className="mb-4  text-xl text-green-400">Main Skills</h3>
          <ul className="grid-cols-3 md:grid-cols-4 grid gap-5">
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              React
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              JavaScript
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              MongoDB
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Node.js
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Express
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Tailwind
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              HTML
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              CSS
            </li>

            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              SQL
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Python
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              C#
            </li>

            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Bootstrap
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Git
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Webhooks
            </li>
            <li className="bg-base-300 px-4 py-4 rounded-2xl flex justify-center shadow-lg">
              Wordpress
            </li>
          </ul>
        </div>

        <div className="mb-8">
          <h3 className="mb-1 text-xl text-green-400">Interests</h3>
          <ul className="">
            <li>Programming</li>
            <li>Graphic design</li>
            <li>Gaming and game development</li>
            <li>Traveling</li>
          </ul>
        </div>

        <div className="font-light italic">
          <p className="text-slate-300">
            This resume is designed for web presentation and provides an
            overview of my skills. For more information, please contact me using
            the contact page provided above.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
